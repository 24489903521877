export default {
  TARGET_YEAR: 2025,
  TARGET_MONTH: 1,
  IS_YEAR_CLOSED: false,
  MAINTENANCE_MODE: true,

  // stats
  ENABLE_REGION_STATS: true,
  ENABLE_PAX_STATS: true,
  ENABLE_PACING_STATS: true,
};
